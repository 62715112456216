import type React from 'react';
import { forwardRef } from 'react';

import { twMerge } from 'tailwind-merge';

export interface SpinnerProps {
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  ({ className, ...props }, ref) => {
    return (
      <svg className={twMerge('size-5 animate-spin', className)} viewBox="0 0 50 50" fill="none" ref={ref} {...props}>
        <circle
          className="animate-dash"
          cx="25"
          cy="25"
          r="20"
          strokeLinecap="round"
          stroke="currentColor"
          strokeWidth="5"
        />
      </svg>
    );
  }
);
Spinner.displayName = 'Spinner';

export { Spinner };
