import React, { useEffect } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { NavPanel } from '~features/nav-panel';
import { StarsBg } from '~features/stars-bg';
import { isDefined } from '~shared/lib';
// import { AppRoutes } from '~shared/model';

interface LayoutProps {
  withNavigation?: boolean;
}

export const Layout: React.FC<LayoutProps> = observer(({ withNavigation = true }) => {
  const { projectId } = useParams();
  const {
    userStore: { getUser, user },
    projectStore: { selectProject, project, rootProject, getProject, getProjects },
  } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    void getUser();
  }, [getUser]);

  useEffect(() => {
    window.Telegram?.WebApp?.BackButton?.onClick(() => {
      navigate(-1);
    });
  }, [navigate]);

  useEffect(() => {
    if (isDefined(user) && isDefined(projectId)) {
      void getProject(projectId, user.id ?? '');
    }
  }, [user, projectId, getProject]);

  useEffect(() => {
    if (isDefined(user)) {
      void getProjects(user.id ?? '');
    }
  }, [getProjects, user]);

  useEffect(() => {
    if (isDefined(rootProject) && isNil(project)) {
      selectProject(rootProject);
    }
  }, [rootProject, project, selectProject]);

  /* useEffect(() => {
    if (isDefined(user) && (isNil(user.twitter_profile) || user.twitter_profile.length === 0)) {
      navigate(AppRoutes.Projects);
    }
  }, [user, navigate]); */

  return (
    <div className="flex h-full flex-col">
      <Outlet />

      {withNavigation && <NavPanel />}

      <StarsBg className="-z-10" />
    </div>
  );
});
