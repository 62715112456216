import type React from 'react';

import { observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';

import { useUserBalance } from '~entities/user/lib';
import { isDefined } from '~shared/lib';
import { Amount, Skeleton } from '~shared/ui';

export interface UserBalanceProps {
  className?: string;
}

export const UserBalance: React.FC<UserBalanceProps> = observer(({ className }) => {
  const userBalance = useUserBalance();

  return isDefined(userBalance) ? (
    <Amount className={className} value={userBalance.formatedBalance} logo={userBalance.logo} />
  ) : (
    <Skeleton className={twMerge('h-[50px] w-[200px]', className)} />
  );
});
