import React from 'react';

import { type VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { HEDGEHOGS_COLOR_MAP } from '~shared/assets/images/hedgehogs';

interface HedgehogProps extends VariantProps<typeof hedgehogEyeVariants> {
  className?: string;
  showGlowing?: boolean;
}

const hedgehogEyeVariants = cva('absolute top-[47%] size-1 animate-glow rounded-full bg-white/50', {
  variants: {
    color: {
      white: 'shadow-[0_0_8px_4px_#246FF2,_0_0_10px_5px_#fff]',
      blue: 'shadow-[0_0_8px_4px_#246FF2,_0_0_10px_5px_#fff]',
      red: 'shadow-[0_0_8px_4px_#E65556,_0_0_10px_5px_#fff]',
      green: 'shadow-[0_0_8px_4px_#37B98C,_0_0_10px_5px_#fff]',
    },
    side: {
      left: 'left-[35%]',
      right: 'right-[35%]',
    },
  },
});

export const Hedgehog: React.FC<HedgehogProps> = ({ className, showGlowing = true, color = 'blue' }) => {
  return (
    <div className={twMerge('relative w-[130px]', className)}>
      <img className="h-auto w-full" src={HEDGEHOGS_COLOR_MAP[color ?? 'blue']} alt="" />
      {showGlowing && (
        <>
          <div className={hedgehogEyeVariants({ color, side: 'left' })} />
          <div className={hedgehogEyeVariants({ color, side: 'right' })} />
        </>
      )}
    </div>
  );
};
