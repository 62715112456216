/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { GetStructureBonusResponse } from '../models/GetStructureBonusResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsStructureBonusesApiService {
  /**
   * Get User Bonuses
   * Get information about the bonuses that were received from the structure
   * @param userId
   * @param projectId
   * @returns GetStructureBonusResponse Successful Response
   * @throws ApiError
   */
  public static getUserBonusesApiV2WidgetUsersUserIdProjectsProjectIdBonusesGet(
    userId: string,
    projectId: string
  ): CancelablePromise<GetStructureBonusResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/bonuses',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Claim Bonuses
   * Claim bonuses. Only if next_claimed_at is equal to the current time
   *
   * RateLimit: 1 request in 5 seconds
   * @param userId
   * @param projectId
   * @returns GetStructureBonusResponse Successful Response
   * @throws ApiError
   */
  public static claimBonusesApiV2WidgetUsersUserIdProjectsProjectIdBonusesClimePost(
    userId: string,
    projectId: string
  ): CancelablePromise<GetStructureBonusResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/bonuses/clime',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
