import { JobStatus } from '~shared/api/client';

export const JOB_STATUS_MAP = {
  [JobStatus.READY]: {
    colorName: 'green',
    qrColor: '#37B98C',
    textClass: 'text-green',
    glowinSphereClass: 'bg-green-glowing-sphere',
  },
  [JobStatus.PROCESSING]: {
    colorName: 'red',
    qrColor: '#C95657',
    textClass: 'text-red',
    glowinSphereClass: 'bg-red-glowing-sphere',
  },
  [JobStatus.COMPLETED]: {
    qrColor: '#246FF2',
    colorName: 'blue',
    textClass: 'text-blue',
    glowinSphereClass: 'bg-blue-glowing-sphere',
  },
} as const;
