import React, { useCallback } from 'react';

// import { useStore } from 'app/store';
import cn from 'classnames';
// import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { generatePath, NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { AirDropsIcon, FriendsIcon, HomeIcon, TasksIcon } from '~shared/assets/icons/navigation';
import { AppRoutes } from '~shared/model';

interface NavPanelProps {
  className?: string;
}

const NavPanel: React.FC<NavPanelProps> = observer(({ className }) => {
  /* const {
    userStore: { user },
  } = useStore(); */

  const handleNavigationClick = useCallback(() => {
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
  }, []);

  return (
    <nav
      className={twMerge(
        'fixed bottom-0 left-0 mx-auto grid w-full grid-cols-[repeat(4,1fr)] gap-[50px] bg-white/10 p-5 text-2xs backdrop-blur-lg',
        className
      )}
    >
      <NavLink
        className={({ isActive }) =>
          cn('flex flex-col items-center transition-all', isActive ? 'text-foreground' : 'text-foreground/50', {
            /* 'opacity-50 pointer-events-none':
              isNil(user) || isNil(user.twitter_profile) || user.twitter_profile.length === 0, */
          })
        }
        onClick={handleNavigationClick}
        to={AppRoutes.Home}
      >
        <HomeIcon className="size-8" />
        <span className="mt-1">Farm</span>
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          cn('flex flex-col items-center transition-all', isActive ? 'text-foreground' : 'text-foreground/50', {
            /* 'opacity-50 pointer-events-none':
              isNil(user) || isNil(user.twitter_profile) || user.twitter_profile.length === 0, */
          })
        }
        onClick={handleNavigationClick}
        to={AppRoutes.Tasks}
      >
        <TasksIcon className="size-8" />
        <span className="mt-1">Tasks</span>
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          cn('flex flex-col items-center transition-all', isActive ? 'text-foreground' : 'text-foreground/50', {
            /* 'opacity-50 pointer-events-none':
              isNil(user) || isNil(user.twitter_profile) || user.twitter_profile.length === 0, */
          })
        }
        onClick={handleNavigationClick}
        to={AppRoutes.Friends}
      >
        <FriendsIcon className="size-8" />
        <span className="mt-1">Friends</span>
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          cn('flex flex-col items-center transition-all', isActive ? 'text-foreground' : 'text-foreground/50')
        }
        onClick={handleNavigationClick}
        to={generatePath(AppRoutes.Projects)}
      >
        <AirDropsIcon className="size-8" />
        <span className="mt-1">AirDrops</span>
      </NavLink>
    </nav>
  );
});
NavPanel.displayName = 'NavPanel';

export { NavPanel };
