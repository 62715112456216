import { TaskType } from '~shared/api/client';

import alchemyIcon from './_alchemy.png';
import baboonIconImg from './_baboon.png';
import bfdIcon from './_bfd.png';
import bitsIcon from './_bits.jpg';
import blueRocketIcon from './_blue-rocket.png';
import boomIconImg from './_boom.jpg';
import bullishFarmIcon from './_bullish-farm.png';
import cornIcon from './_corn.png';
import cryptoRankIcon from './_crypto-rank.png';
import { ReactComponent as DebankIcon } from './_debank.svg';
import diaMoreIcon from './_dia-more.png';
import { ReactComponent as DiscordIcon } from './_discord.svg';
import duckCoopIcon from './_duck-coop.png';
import easyCakeIcon from './_easy-cake.png';
import goldMinerIcon from './_gold-miner.jpg';
import hexcoinIconImg from './_hexcoin.png';
import holdcoinIconImg from './_holdcoin.png';
import { ReactComponent as InstagramIcon } from './_instagram.svg';
import koloIconImg from './_kolo.png';
import memeFiIcon from './_meme-fi.png';
import nomisIcon from './_nomis.png';
import onusIcon from './_onus.png';
import piggyIcon from './_piggy.png';
import pixelsIcon from './_pixels.png';
import rocketIconImg from './_rocket.png';
import shuttleIcon from './_shuttle.jpg';
import { ReactComponent as TelegramIcon } from './_telegram.svg';
import timeFarmIcon from './_time-farm.png';
import tomIconImg from './_tom.png';
import tomarketIcon from './_tomarket.jpg';
import tonChurchIconImg from './_ton-church.png';
import { ReactComponent as XIcon } from './_x.svg';
import { ReactComponent as YoutubeIcon } from './_youtube.svg';
import zigzagIcon from './_zigzag.png';

export const TASK_TYPE_MAP = {
  [TaskType.DE_BANK]: DebankIcon,
  [TaskType.DISCORD]: DiscordIcon,
  [TaskType.INSTAGRAM]: InstagramIcon,
  [TaskType.TELEGRAM]: TelegramIcon,
  [TaskType.TELEGRAM_SUBSCRIBE]: TelegramIcon,
  [TaskType.TWITTER]: XIcon,
  [TaskType.TWITTER_RETWEET]: XIcon,
  [TaskType.TWITTER_SUBSCRIBE]: XIcon,
  [TaskType.YOUTUBE]: YoutubeIcon,
  [TaskType.TON_CHURCH]: tonChurchIconImg,
  [TaskType.HOLD_COIN]: holdcoinIconImg,
  [TaskType.HEXN]: hexcoinIconImg,
  [TaskType.BOOM]: boomIconImg,
  [TaskType.BABOON]: baboonIconImg,
  [TaskType.ROCKET]: rocketIconImg,
  [TaskType.TOM]: tomIconImg,
  [TaskType.KOLO]: koloIconImg,
  [TaskType.TOMARKET]: tomarketIcon,
  [TaskType.BFD]: bfdIcon,
  [TaskType.CORN]: cornIcon,
  [TaskType.PIGGY]: piggyIcon,
  [TaskType.GOLD_MINER]: goldMinerIcon,
  [TaskType.BULLISH_FARM]: bullishFarmIcon,
  [TaskType.ZIGZAG]: zigzagIcon,
  [TaskType.EASY_CAKE]: easyCakeIcon,
  [TaskType.DIA_MORE]: diaMoreIcon,
  [TaskType.CRYPTO_RANK]: cryptoRankIcon,
  [TaskType.MEME_FI]: memeFiIcon,
  [TaskType.TIME_FARM]: timeFarmIcon,
  [TaskType.ONUS]: onusIcon,
  [TaskType.NOMIS]: nomisIcon,
  [TaskType.BITS]: bitsIcon,
  [TaskType.SHUTTLE]: shuttleIcon,
  [TaskType.DUCK_COOP]: duckCoopIcon,
  [TaskType.ALCHEMY]: alchemyIcon,
  [TaskType.BLUE_ROCKET]: blueRocketIcon,
  [TaskType.PIXELS]: pixelsIcon,
} as const;

export { DebankIcon, DiscordIcon, InstagramIcon, TelegramIcon, XIcon, YoutubeIcon };
