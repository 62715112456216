import React, { forwardRef } from 'react';

import cn from 'classnames';
import { observer } from 'mobx-react';
import { QRCode } from 'react-qrcode-logo';
import { twMerge } from 'tailwind-merge';

import { useUserRefLink } from '~entities/user/lib';
import { isDefined } from '~shared/lib';
import { Skeleton } from '~shared/ui';

export interface RefQrProps {
  className?: string;
  qrHexColor: string;
  usernameColorClassName: string;
  sphereColorClassName: string;
}

const RefQrBase = forwardRef<HTMLDivElement, RefQrProps>(
  ({ className, qrHexColor, usernameColorClassName, sphereColorClassName }, ref) => {
    const userRefInfo = useUserRefLink();

    return (
      <div className={twMerge('relative flex flex-col items-center', className)} ref={ref}>
        {isDefined(userRefInfo) ? (
          <>
            <QRCode
              style={{ transition: 'all .3s ease-in-out' }}
              fgColor={qrHexColor}
              bgColor="#272B3B"
              size={140}
              value={userRefInfo.refLink}
              qrStyle="squares"
              logoWidth={32}
              logoHeight={32}
              eyeRadius={0}
              removeQrCodeBehindLogo
            />
            {isDefined(userRefInfo.username) && (
              <p className={cn('text-lg font-bold transition-all', usernameColorClassName)}>{userRefInfo.username}</p>
            )}
            <div
              className={cn(
                'pointer-events-none absolute bottom-0 left-1/2 z-10 size-[320px] -translate-x-1/2 opacity-35 blur-xl transition-all',
                sphereColorClassName
              )}
            />
          </>
        ) : (
          <>
            <Skeleton className="size-[170px]" />
            <Skeleton className="mt-3 h-7 w-[180px]" />
          </>
        )}
      </div>
    );
  }
);
RefQrBase.displayName = 'RefQr';
const RefQr = observer(RefQrBase);

export { RefQr };
