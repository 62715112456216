import React, { useCallback, useRef, useState } from 'react';

import cn from 'classnames';
import { toBlob } from 'html-to-image';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { useUserRefLink } from '~entities/user/lib';
import { ClipboardCopy } from '~features/clipboard-copy';
import { RefQr } from '~features/ref-qr';
import { useToast } from '~features/toaster/lib';
import { CopyIcon, QrIcon, ShareIcon } from '~shared/assets/icons';
import { isDefined } from '~shared/lib';
import { Button, Drawer, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, Hedgehog } from '~shared/ui';

interface ShareDrawerProps {
  isOpen: boolean;
  onClose: (newOpen: boolean) => void;
}

export const ShareDrawer: React.FC<ShareDrawerProps> = observer(({ isOpen, onClose }) => {
  const userRefInfo = useUserRefLink();
  const { toast, dismiss } = useToast();
  const qrRef = useRef<HTMLDivElement | null>(null);

  const [isQrCopyLoading, setIsQrCopyLoading] = useState(false);
  const [isShareLoading, setIsShareLoading] = useState(false);

  const handleClose = useCallback(
    (newOpen: boolean) => {
      onClose(newOpen);
    },
    [onClose]
  );

  const jsxToBlob = useCallback(async () => {
    if (isNil(qrRef.current)) return;

    let data: Blob | null = new Blob();
    const minDataLength = 2000000;
    let i = 0;
    const maxAttempts = 10;

    while ((data?.size ?? 0) < minDataLength && i < maxAttempts) {
      console.log(data?.size);
      data = await toBlob(qrRef.current);
      i += 1;
    }

    return data;
  }, [qrRef]);

  const handleCopyQr = useCallback(async () => {
    if (isNil(navigator.clipboard) || isNil(qrRef.current)) return;
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    try {
      setIsQrCopyLoading(true);

      await navigator.clipboard.write([
        new ClipboardItem({
          // @ts-expect-error: Unreachable code error
          'image/png': jsxToBlob(),
        }),
      ]);
      const toastedMessage = toast({ title: 'Qr copied' });
      setTimeout(() => {
        dismiss(toastedMessage.id);
      }, 2000);
      handleClose(false);
    } catch (error) {
      console.error(error);
      const toastedMessage = toast({ title: 'Error copying ' });

      setTimeout(() => {
        dismiss(toastedMessage.id);
      }, 2000);
    } finally {
      setIsQrCopyLoading(false);
    }
  }, [toast, dismiss, handleClose, jsxToBlob]);

  const handleShare = useCallback(async () => {
    if (isNil(userRefInfo)) return;
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    try {
      setIsShareLoading(true);
      await navigator.share({ url: userRefInfo.refLink });
      handleClose(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsShareLoading(false);
    }
  }, [userRefInfo, handleClose]);

  return (
    <Drawer open={isOpen} onOpenChange={handleClose}>
      <DrawerContent>
        <DrawerHeader className="flex flex-col items-center">
          <DrawerTitle>Share link:</DrawerTitle>
        </DrawerHeader>

        <div className="h-0 overflow-hidden">
          <div className="flex flex-col items-center bg-background p-4" ref={qrRef}>
            <Hedgehog className="mt-auto" color="green" showGlowing={false} />

            <h1 className="text-lg font-bold">GraphDex</h1>

            <RefQr
              className="mt-8"
              qrHexColor="#37B98C"
              sphereColorClassName="bg-green-glowing-sphere"
              usernameColorClassName="text-green"
            />
          </div>
        </div>

        {/* eslint-disable-next-line @typescript-eslint/unbound-method */}
        <DrawerFooter className={cn('grid gap-4', isDefined(navigator.share) ? 'grid-cols-3' : 'grid-cols-2')}>
          <ClipboardCopy
            variant="wrapper"
            size="wrapper"
            value={userRefInfo?.refLink ?? ''}
            label="Link copied"
            onClick={() => {
              Telegram.WebApp.HapticFeedback.notificationOccurred('success');
              handleClose(false);
            }}
          >
            <CopyIcon className="size-10" />
          </ClipboardCopy>
          <Button variant="wrapper" size="wrapper" onClick={handleCopyQr} isLoading={isQrCopyLoading}>
            <QrIcon className="size-10" />
          </Button>
          {/* eslint-disable-next-line @typescript-eslint/unbound-method */}
          {isDefined(navigator.share) && (
            <Button variant="wrapper" size="wrapper" onClick={handleShare} isLoading={isShareLoading}>
              <ShareIcon className="size-10" />
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
