import React, { useCallback, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { Link, generatePath } from 'react-router-dom';

import { PeopleIcon } from '~shared/assets/icons';
import { formatAmount, isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';
import { Amount, AnimatedCounter, Button, Hedgehog, Page, Skeleton } from '~shared/ui';
import { ShareDrawer } from '~widgets/share-drawer';

import { useBonuses, useStructureCount } from '../lib';

export const FriendsPage: React.FC = observer(() => {
  const {
    projectStore: { project },
  } = useStore();
  const [isShareDrawerOpen, setIsShareDrawerOpen] = useState(false);
  const { bonuses, minutesRemaining, handleClaimBonuses, isClaimLoading } = useBonuses();
  const structureCount = useStructureCount();

  const handleShareDrawerOpen = useCallback(() => {
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    setIsShareDrawerOpen(true);
  }, []);

  return (
    <>
      <Page>
        <h1 className="text-center text-lg font-bold">Invite Friends</h1>
        <p className="text-center text-white/50">
          Get 10% from your friends + 5% from their referrals + 2.5% from their referrals
        </p>

        <div className="mt-4 flex flex-col items-center rounded-[10px] border-2 border-white/50 p-5">
          {isDefined(bonuses) ? (
            <Amount
              className="[&>*]:text-xl [&>img]:mr-2 [&>img]:size-6"
              value={formatAmount(parseFloat(bonuses.amount))}
              logo={project?.logo_url}
            />
          ) : (
            <Skeleton className="h-[50px] w-[200px]" />
          )}

          {isDefined(bonuses) && bonuses.can_clime && (
            <Button
              className="mt-4 px-8 py-2 text-md font-bold"
              buttonColor="green"
              onClick={handleClaimBonuses}
              isLoading={isClaimLoading}
            >
              Claim
            </Button>
          )}

          {isDefined(bonuses) && !bonuses.can_clime && (
            <Button className="mt-4 px-8 py-2 text-md font-bold" buttonColor="red" disabled>
              Claim{' '}
              {minutesRemaining > 0 && (
                <>
                  in <AnimatedCounter className="ml-[0.5ch]" value={Math.trunc(minutesRemaining / 60).toString()} />
                  h <AnimatedCounter className="ml-[0.5ch]" value={(minutesRemaining % 60).toString()} />m
                </>
              )}
            </Button>
          )}

          {isNil(bonuses) && <Skeleton className="mt-4 h-10 w-[120px]" />}
        </div>

        <div className="mx-auto my-2 flex flex-col">
          <div className="flex w-full items-center justify-around">
            <Button asChild variant="wrapper" size="wrapper">
              <Link className="flex flex-col items-center" to={generatePath(AppRoutes.LevelFriends, { level: '2' })}>
                {isDefined(project) && project?.is_root ? (
                  <Hedgehog className="w-[60px]" color="blue" />
                ) : isDefined(project) ? (
                  <img className="size-[60px] overflow-hidden rounded-full" src={project.logo_url ?? ''} alt="" />
                ) : (
                  <Skeleton className="size-[60px] rounded-full" />
                )}
                <span className="mt-2 text-md font-bold">2 lvl</span>
                {isDefined(structureCount) ? (
                  <div className="mt-2 flex items-center text-white/50">
                    <PeopleIcon className="mr-1 size-10" />
                    <span>{structureCount.level2}</span>
                  </div>
                ) : (
                  <Skeleton className="mt-2 h-10 w-[54px]" />
                )}
              </Link>
            </Button>

            <Button asChild variant="wrapper" size="wrapper">
              <Link className="flex flex-col items-center" to={generatePath(AppRoutes.LevelFriends, { level: '1' })}>
                {isDefined(project) && project?.is_root ? (
                  <Hedgehog className="w-[100px]" color="green" />
                ) : isDefined(project) ? (
                  <img className="size-[100px] overflow-hidden rounded-full" src={project.logo_url ?? ''} alt="" />
                ) : (
                  <Skeleton className="size-[100px] rounded-full" />
                )}
                <span className="mt-2 text-md font-bold">1 lvl</span>
                {isDefined(structureCount) ? (
                  <div className="mt-2 flex items-center text-white/50">
                    <PeopleIcon className="mr-1 size-10" />
                    <span>{structureCount.level1}</span>
                  </div>
                ) : (
                  <Skeleton className="mt-2 h-10 w-[54px]" />
                )}
              </Link>
            </Button>

            <Button asChild variant="wrapper" size="wrapper">
              <Link className="flex flex-col items-center" to={generatePath(AppRoutes.LevelFriends, { level: '3' })}>
                {isDefined(project) && project?.is_root ? (
                  <Hedgehog className="w-[60px]" color="red" />
                ) : isDefined(project) ? (
                  <img className="size-[60px] overflow-hidden rounded-full" src={project.logo_url ?? ''} alt="" />
                ) : (
                  <Skeleton className="size-[60px] rounded-full" />
                )}
                <span className="mt-2 text-md font-bold">3 lvl</span>
                {isDefined(structureCount) ? (
                  <div className="mt-2 flex items-center text-white/50">
                    <PeopleIcon className="mr-1 size-10" />
                    <span>{structureCount.level3}</span>
                  </div>
                ) : (
                  <Skeleton className="mt-2 h-10 w-[54px]" />
                )}
              </Link>
            </Button>
          </div>

          {isDefined(structureCount) && structureCount.level1 + structureCount.level2 + structureCount.level3 === 0 && (
            <p className="mt-4 text-center text-red">No friends yet</p>
          )}
        </div>

        <Button className="mt-auto" onClick={handleShareDrawerOpen}>
          Invite a friends
        </Button>
      </Page>

      <ShareDrawer
        isOpen={isShareDrawerOpen}
        onClose={(newOpen) => {
          setIsShareDrawerOpen(newOpen);
        }}
      />
    </>
  );
});
