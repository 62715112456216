import { useCallback, useEffect, useState } from 'react';

import { useStore } from 'app/store';

import { type GetStructuresListResponse, type GetCountResponse, WidgetsStructuresApiService } from '~shared/api/client';
import { isDefined } from '~shared/lib';

export type LevelStructureInfo = {
  structure: GetStructuresListResponse | null;
  structureCount: GetCountResponse | null;
  handleLoadMore: () => void;
  isStructureLoading: boolean;
};

export const useLevelStructure = (level?: string): LevelStructureInfo => {
  const {
    userStore: { user },
    projectStore: { project },
  } = useStore();
  const [isStructureLoading, setIsStructureLoading] = useState(false);

  const [structure, setStructure] = useState<GetStructuresListResponse | null>(null);
  const [structureCount, setStructureCount] = useState<GetCountResponse | null>(null);
  const [currentLimit, setCurrentLimit] = useState(20);

  const handleGetStructure = useCallback(async (id: string, projectId: string, depth: number, limit: number) => {
    try {
      setIsStructureLoading(true);
      const structureResponse = await WidgetsStructuresApiService.getStructuresApiV2WidgetUsersUserIdStructuresGet(
        id,
        depth,
        projectId,
        limit
      );
      setStructure(structureResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setIsStructureLoading(false);
    }
  }, []);

  const handleLoadMore = useCallback(() => {
    setCurrentLimit((prev) => prev + 20);
  }, []);

  const handleGetStructureCount = useCallback(async (id: string, depth: number) => {
    try {
      const structureCountResponse =
        await WidgetsStructuresApiService.getStructuresCountApiV2WidgetUsersUserIdStructuresCountGet(id, depth);
      setStructureCount(structureCountResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id) && isDefined(level) && isDefined(project) && isDefined(project.id)) {
      void handleGetStructure(user.id, project.id, parseInt(level), currentLimit);
      void handleGetStructureCount(user.id, parseInt(level));
    }
  }, [handleGetStructure, handleGetStructureCount, user, project, level, currentLimit]);

  return { structure, structureCount, handleLoadMore, isStructureLoading };
};
