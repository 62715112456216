import React, { useCallback } from 'react';

import { type UserProjectSchema } from '~shared/api/client';
import { isDefined } from '~shared/lib';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '~shared/ui';

interface ProjectDescriptionDrawerProps {
  project: UserProjectSchema | null;
  onClose: () => void;
}

const ProjectDescriptionDrawer: React.FC<ProjectDescriptionDrawerProps> = React.memo(({ project, onClose }) => {
  const handleClose = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Drawer open={isDefined(project)} onOpenChange={handleClose} shouldScaleBackground>
      <DrawerContent className="flex flex-col pb-0">
        <div className="flex h-min flex-1 flex-col overflow-auto pb-12">
          <DrawerHeader className="flex flex-col items-center">
            <img className="mb-3 size-[100px] overflow-hidden rounded-full" src={project?.logo_url ?? ''} alt="" />

            <DrawerTitle>{project?.name}</DrawerTitle>
            {isDefined(project) && isDefined(project.url) && (
              <a className="text-blue" href={project.url} target="_blank" rel="noreferrer">
                Project Website
              </a>
            )}
            <DrawerDescription>{project?.final_info}</DrawerDescription>
          </DrawerHeader>
        </div>
      </DrawerContent>
    </Drawer>
  );
});
ProjectDescriptionDrawer.displayName = 'ProjectDescriptionDrawer';

export { ProjectDescriptionDrawer };
