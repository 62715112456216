import React from 'react';

import { JobStore } from '~entities/job';
import { ProjectStore } from '~entities/project';
import { UserStore } from '~entities/user';

interface StoreProviderProps extends React.PropsWithChildren {
  store: RootStore;
}

export class RootStore {
  userStore: UserStore;
  jobStore: JobStore;
  projectStore: ProjectStore;

  constructor() {
    this.userStore = new UserStore();
    this.jobStore = new JobStore();
    this.projectStore = new ProjectStore();
  }
}

// @ts-expect-error: Always initialized after
export const StoreContext = React.createContext<RootStore>();

export const StoreProvider: React.FC<StoreProviderProps> = ({ store, children }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useStore = (): RootStore => React.useContext(StoreContext);
