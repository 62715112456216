import type React from 'react';

import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';

import { useLatestJob } from '~entities/job';
import { ClaimJobButton } from '~features/claim-job-button';
import { JobProgressButton } from '~features/job-progress-button';
import { StartJobButton } from '~features/start-job-button';
import { JobStatus } from '~shared/api/client';
import { Skeleton } from '~shared/ui';

interface JobStatusButtonProps {
  className?: string;
}

export const JobStatusButton: React.FC<JobStatusButtonProps> = observer(({ className }) => {
  const { latestJob, loaded } = useLatestJob();
  const jobStatus = latestJob?.status ?? null;

  return (
    <>
      {isNil(jobStatus) && !loaded && <Skeleton className={twMerge('h-[60px] w-full', className)} />}

      {((isNil(jobStatus) && loaded) || jobStatus === JobStatus.COMPLETED) && <StartJobButton className={className} />}

      {jobStatus === JobStatus.PROCESSING && <JobProgressButton className={className} />}

      {jobStatus === JobStatus.READY && <ClaimJobButton className={className} />}
    </>
  );
});
