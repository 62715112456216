/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { UpdateTwitterProfileSchema } from '../models/UpdateTwitterProfileSchema';
import type { UserSchema } from '../models/UserSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsUsersApiV2Service {
  /**
   * Get User By Telegram Id
   * @param userId
   * @param requestBody
   * @returns UserSchema Successful Response
   * @throws ApiError
   */
  public static getUserByTelegramIdApiV2WidgetUsersUserIdTwitterPut(
    userId: string,
    requestBody: UpdateTwitterProfileSchema
  ): CancelablePromise<UserSchema> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v2/widget/users/{user_id}/twitter',
      path: {
        user_id: userId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
