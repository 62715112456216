import { useStore } from 'app/store';

import { isDefined } from '~shared/lib';
import { BOT_NAME } from '~shared/model';

export type UserRefLinkInfo = {
  refLink: string;
  username: string | null;
};

export const useUserRefLink = (): UserRefLinkInfo | null => {
  const {
    userStore: { user },
  } = useStore();

  return isDefined(user)
    ? {
        refLink: `https://t.me/${BOT_NAME}?start=${user.telegram_id}`,
        username: isDefined(user.username) ? `@${user.username}` : null,
      }
    : null;
};
