import React, { useCallback, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { twMerge } from 'tailwind-merge';

import { Button } from '~shared/ui';

export interface StartJobButtonProps {
  className?: string;
}

export const StartJobButton: React.FC<StartJobButtonProps> = observer(({ className }) => {
  const {
    userStore: { user },
    jobStore: { startJob },
    projectStore: { project },
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleStartJob = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id)) return;
    setIsLoading(true);
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    await startJob(project.id, user.id);
    setIsLoading(false);
  }, [user, project, startJob]);

  return (
    <Button
      className={twMerge('w-full', className)}
      buttonColor="blue"
      onClick={handleStartJob}
      disabled={isNil(user)}
      isLoading={isLoading}
    >
      Start farming
    </Button>
  );
});
