import { useStore } from 'app/store';

import { type JobSchema } from '~shared/api/client';

export type LatestJobInfo = {
  latestJob: JobSchema | null;
  loaded: boolean;
};

export const useLatestJob = (): LatestJobInfo => {
  const {
    jobStore: { latestJob, loaded },
  } = useStore();

  return {
    latestJob,
    loaded: loaded.latestJob,
  };
};
