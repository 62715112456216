import { isEmpty, orderBy } from 'lodash';
import { makeAutoObservable } from 'mobx';

import { type GetUserProjectsListResponse, type UserProjectSchema, WidgetsProjectApiService } from '~shared/api/client';
import { isDefined } from '~shared/lib';

export class ProjectStore {
  projects: GetUserProjectsListResponse | null;

  project: UserProjectSchema | null;

  loaded: {
    project: boolean;
    projects: boolean;
  };

  constructor() {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'projectStore' });

    this.projects = null;
    this.project = null;
    this.loaded = {
      project: false,
      projects: false,
    };
  }

  get projectColor(): 'blue' | 'red' | 'green' {
    return isDefined(this.project) && isEmpty(this.project.color)
      ? 'blue'
      : (this.project?.color as 'red' | 'green' | 'blue');
  }

  get projectSymbol(): string | null | undefined {
    return isDefined(this.project) && this.project.is_root ? null : this.project?.symbol ?? null;
  }

  get rootProject(): UserProjectSchema | null {
    return this.projects?.results.find((project) => project.is_root) ?? null;
  }

  resetProject(): void {
    this.project = null;
  }

  selectProject(project: UserProjectSchema): void {
    this.project = project;
  }

  async getProjects(userId: string): Promise<GetUserProjectsListResponse | undefined> {
    try {
      const projectsResponse = await WidgetsProjectApiService.getListByUserIdApiV2WidgetUsersUserIdProjectsGet(userId);
      const normalizedProjects = {
        ...projectsResponse,
        results: orderBy(projectsResponse.results, (project) => project.is_root, 'desc'),
      };
      this.projects = normalizedProjects;
      return normalizedProjects;
    } catch (error) {
      console.error(error);
    } finally {
      this.loaded.projects = true;
    }
  }

  async getProject(projectId: string, userId: string): Promise<UserProjectSchema | undefined> {
    try {
      const projectResponse = await WidgetsProjectApiService.getByUserIdApiV2WidgetUsersUserIdProjectsProjectIdGet(
        userId,
        projectId
      );
      this.project = projectResponse;
      return projectResponse;
    } catch (error) {
      console.error(error);
    } finally {
      this.loaded.project = true;
    }
  }
}
