import React, { useCallback, useState } from 'react';

import { useStore } from 'app/store';
import cn from 'classnames';
import { isNil } from 'lodash';
import { twMerge } from 'tailwind-merge';

import { useLatestJob } from '~entities/job';
import coinImage from '~shared/assets/images/_coin.png';
import { formatAmount, isDefined } from '~shared/lib';
import { Button } from '~shared/ui';

export interface ClaimJobButtonProps {
  className?: string;
}

export const ClaimJobButton: React.FC<ClaimJobButtonProps> = ({ className }) => {
  const { latestJob } = useLatestJob();
  const {
    jobStore: { claimJobReward },
    userStore: { user, getUser },
    projectStore: { project, getProject },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  const handleClaimJobReward = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(latestJob) || isNil(latestJob.id) || isNil(project) || isNil(project.id))
      return;
    setIsLoading(true);
    Telegram.WebApp.HapticFeedback.notificationOccurred('success');
    await claimJobReward(project.id, user.id, latestJob.id);
    await getUser();
    await getProject(project.id, user.id);
    setIsLoading(false);
  }, [claimJobReward, getUser, project, user, latestJob, getProject]);

  return (
    <Button
      className={twMerge('w-full', className)}
      buttonColor="green"
      isLoading={isLoading}
      onClick={handleClaimJobReward}
    >
      Claim{' '}
      <span className="ml-2 flex items-center font-bold">
        {isDefined(project) && (
          <img
            className={cn('mr-1 size-6', { 'overflow-hidden rounded-full': !project.is_root })}
            src={project.is_root ? coinImage : project.logo_url ?? ''}
            alt=""
          />
        )}{' '}
        {formatAmount(latestJob?.amount ?? 0)}
      </span>
    </Button>
  );
};
