import blueHedgehogImage from './_blue-hedgehog.png';
import greenHedgehogImage from './_green-hedgehog.png';
import redHedgehogImage from './_red-hedgehog.png';

export const HEDGEHOGS_COLOR_MAP = {
  red: redHedgehogImage,
  blue: blueHedgehogImage,
  green: greenHedgehogImage,
  white: blueHedgehogImage,
} as const;

export { blueHedgehogImage, greenHedgehogImage, redHedgehogImage };
