/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { GetUserProjectsListResponse } from '../models/GetUserProjectsListResponse';
import type { UserProjectSchema } from '../models/UserProjectSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsProjectApiService {
  /**
   * Get List By User Id
   * @param userId
   * @returns GetUserProjectsListResponse Successful Response
   * @throws ApiError
   */
  public static getListByUserIdApiV2WidgetUsersUserIdProjectsGet(
    userId: string
  ): CancelablePromise<GetUserProjectsListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/projects',
      path: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get By User Id
   * @param userId
   * @param projectId
   * @returns UserProjectSchema Successful Response
   * @throws ApiError
   */
  public static getByUserIdApiV2WidgetUsersUserIdProjectsProjectIdGet(
    userId: string,
    projectId: string
  ): CancelablePromise<UserProjectSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
