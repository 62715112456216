import axios from 'axios';

import { API_BASE_URL, API_KEY } from '~shared/model';

import { OpenAPI as ApiConfig, type CancelablePromise } from './client';
import { type ApiRequestOptions } from './client/core/ApiRequestOptions';
import { request } from './client/core/request';

ApiConfig.BASE = API_BASE_URL;

axios.interceptors.request.use((config) => {
  config.headers['x-api-key'] = API_KEY;
  config.headers['X-TG-INIT-DATA'] = window.Telegram.WebApp.initData;
  return config;
});

export const apiRequest = (options: ApiRequestOptions): CancelablePromise<unknown> => request(ApiConfig, options);
