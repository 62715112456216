import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useStore } from 'app/store';
import cn from 'classnames';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { useToast } from '~features/toaster/lib';
import { type ApiError, type UserProjectSchema, WidgetsWalletsApiService } from '~shared/api/client';
import { isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';
import {
  Button,
  Checkbox,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  Input,
} from '~shared/ui';

interface ProjectDrawerProps {
  project: UserProjectSchema | null;
  onClose: () => void;
}

export const ProjectDrawer: React.FC<ProjectDrawerProps> = observer(({ project, onClose }) => {
  const {
    userStore: { user },
    projectStore: { selectProject },
  } = useStore();

  const { toast } = useToast();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [wallet, setWallet] = useState<string>('');
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [isConnectLoading, setIsConnectLoading] = useState(false);

  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleClose = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        onClose();
        setTermsAccepted(false);
        setIsWalletConnected(false);
      }
    },
    [onClose]
  );

  const getProjectWallet = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id)) return;
    try {
      const walletResponse =
        await WidgetsWalletsApiService.getSelfWalletApiV2WidgetUsersUserIdProjectsProjectIdWalletsSelfGet(
          user.id,
          project.id
        );
      const walletAddress = walletResponse.address ?? '';
      setWallet(walletAddress);
      setIsWalletConnected(walletAddress.length > 0);
    } catch (error) {
      console.error(error);
    }
  }, [project, user]);

  const connectWalllet = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id)) return;
    try {
      setIsConnectLoading(true);
      await WidgetsWalletsApiService.updateSelfWalletApiV2WidgetUsersUserIdProjectsProjectIdWalletsSelfPut(
        user.id,
        project.id,
        { address: wallet }
      );
      toast({
        title: 'Wallet Connected',
      });
      setIsWalletConnected(true);
    } catch (error) {
      toast({
        title:
          ((error as ApiError)?.body as { detail: { msg: string } })?.detail?.msg ??
          ((error as ApiError)?.body as { detail: Array<{ msg: string }> })?.detail?.[0]?.msg ??
          'Unknown error',
      });
    } finally {
      setIsConnectLoading(false);
    }
  }, [project, user, wallet, toast]);

  useEffect(() => {
    void getProjectWallet();
  }, [getProjectWallet]);

  return (
    <Drawer open={isDefined(project)} onOpenChange={handleClose} shouldScaleBackground>
      <DrawerContent className="flex flex-col pb-0">
        <div className="flex h-min flex-1 flex-col overflow-auto pb-12" ref={scrollRef}>
          <DrawerHeader className="flex flex-col items-center">
            <img className="mb-3 size-[100px] overflow-hidden rounded-full" src={project?.logo_url ?? ''} alt="" />

            <DrawerTitle>{project?.name}</DrawerTitle>
            {isDefined(project) && isDefined(project.url) && (
              <a className="text-blue" href={project.url} target="_blank" rel="noreferrer">
                Project Website
              </a>
            )}
            <DrawerDescription>{project?.description}</DrawerDescription>
          </DrawerHeader>

          <div className="mx-auto flex items-center space-x-2">
            <Checkbox
              id="terms"
              checked={termsAccepted}
              onCheckedChange={(checkedState) => {
                setTermsAccepted(Boolean(checkedState));
              }}
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              I accept project{' '}
              <Link className="text-blue" to={AppRoutes.TermsAndConditions}>
                terms and conditions
              </Link>
            </label>
          </div>

          {!isWalletConnected && (
            <Input
              value={wallet}
              className="mt-6"
              placeholder={isDefined(project?.network) ? `Wallet Address (${project?.network})` : 'Wallet Address'}
              onChange={(event) => {
                setWallet(event.target.value);
              }}
              onFocus={() => {
                setTimeout(() => {
                  scrollRef.current?.scrollTo({
                    behavior: 'smooth',
                    top: scrollRef.current.scrollHeight - scrollRef.current.clientHeight,
                  });
                }, 1000);
              }}
            />
          )}

          {isWalletConnected ? (
            <Button
              className={cn('mt-6', { 'pointer-events-none opacity-50': !termsAccepted })}
              buttonColor="blue"
              asChild
              onClick={() => {
                isDefined(project) && selectProject(project);
              }}
            >
              <Link to={AppRoutes.Home}>Go</Link>
            </Button>
          ) : (
            <Button
              className="mt-4"
              buttonColor="green"
              disabled={wallet.length === 0}
              onClick={connectWalllet}
              isLoading={isConnectLoading}
            >
              Connect Wallet
            </Button>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
});
