import React, { useCallback, useEffect } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { useLatestJob } from '~entities/job';
import { RefQr } from '~features/ref-qr';
import { UserBalance } from '~features/user-balance';
import { QuestionIcon } from '~shared/assets/icons';
import { ChevronLeftIcon } from '~shared/assets/icons/chevrons';
import { isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';
import { Page, Hedgehog, Skeleton, Button } from '~shared/ui';
import { JobStatusButton } from '~widgets/job-status-button/ui/job-status-button.component';

import { JOB_STATUS_MAP } from '../model';

export const HomePage: React.FC = observer(() => {
  const {
    jobStore: { getLatestJob, resetJob },
    projectStore: { project, rootProject, selectProject },
    userStore: { user },
  } = useStore();
  const { latestJob, loaded } = useLatestJob();

  const colorScheme = isDefined(latestJob)
    ? JOB_STATUS_MAP[latestJob.status]
    : loaded
      ? JOB_STATUS_MAP.COMPLETED
      : null;

  useEffect(() => {
    if (isDefined(project) && isDefined(user)) {
      void getLatestJob(project.id ?? '', user.id ?? '');
    }
  }, [project, user, getLatestJob]);

  const handleBackToGraphdex = useCallback(() => {
    if (isDefined(rootProject)) {
      resetJob();
      selectProject(rootProject);
    }
  }, [resetJob, rootProject, selectProject]);

  return (
    <Page className="items-center" background={colorScheme?.colorName ?? 'multicolor'}>
      <div className="mb-4 flex w-full justify-start">
        {isDefined(project) && !project.is_root ? (
          <Button className="p-2 text-base" buttonColor={colorScheme?.colorName} onClick={handleBackToGraphdex}>
            <ChevronLeftIcon className="mr-2 size-4 shrink-0" />
            Back to Graphdex
          </Button>
        ) : (
          <Button className="p-2 text-base" buttonColor={colorScheme?.colorName} onClick={handleBackToGraphdex}>
            <Link className="flex items-center" to={AppRoutes.TermsAndConditions}>
              <QuestionIcon className="mr-2 size-4 shrink-0" />
              Terms and conditions
            </Link>
          </Button>
        )}
      </div>
      {isDefined(project) && project.is_root ? (
        <Hedgehog className="mt-auto w-[120px]" color={colorScheme?.colorName ?? 'white'} />
      ) : isDefined(project) ? (
        <img className="mt-auto w-[120px] overflow-hidden rounded-full" src={project.logo_url ?? ''} alt="" />
      ) : (
        <Skeleton className="mt-auto h-[100px] w-[120px] rounded-full" />
      )}

      <h1 className="mt-2 text-lg font-bold">{project?.name}</h1>

      <UserBalance className="mt-5" />

      <RefQr
        className="mb-5 mt-8"
        qrHexColor={colorScheme?.qrColor ?? '#fff'}
        sphereColorClassName={colorScheme?.glowinSphereClass ?? 'bg-transparent'}
        usernameColorClassName={colorScheme?.textClass ?? 'text-white'}
      />

      <JobStatusButton className="mt-auto" />
    </Page>
  );
});
