import { isNil } from 'lodash';

import { isDefined } from '~shared/lib';

const fallbackCopyTextToClipboard = (text: string): void => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.width = '0';
  textArea.style.height = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = async (data: string | ClipboardItems): Promise<void> => {
  if (isNil(navigator.clipboard) && typeof data === 'string') {
    fallbackCopyTextToClipboard(data);
  } else if (isDefined(navigator.clipboard) && typeof data === 'string') {
    await navigator.clipboard.writeText(data);
  } else if (isDefined(navigator.clipboard) && typeof data !== 'string') {
    await navigator.clipboard.write(data);
  }
};
