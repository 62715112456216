import React from 'react';

import { isNil } from 'lodash';

import { useToast } from '~features/toaster/lib';
import { isDefined } from '~shared/lib';
import { Button, type ButtonProps } from '~shared/ui';

import { copyTextToClipboard } from '../lib';

type ClipboardCopyProps = {
  value: string;
  label?: string;
} & ButtonProps;

const ClipboardCopy = React.forwardRef<HTMLButtonElement, ClipboardCopyProps>(
  ({ value, label, onClick, ...props }, ref) => {
    const { toast, dismiss } = useToast();

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
      isDefined(onClick) && onClick(event);
      if (isNil(value)) return;

      await copyTextToClipboard(value);
      const toastedMessage = toast({ title: label ?? 'Copied to clipboard' });

      setTimeout(() => {
        dismiss(toastedMessage.id);
      }, 2000);
    };

    return <Button ref={ref} {...props} onClick={handleClick} />;
  }
);
ClipboardCopy.displayName = 'ClipboardCopy';

export { ClipboardCopy, type ClipboardCopyProps };
