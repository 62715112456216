/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
export enum TaskType {
  TWITTER = 'TWITTER',
  TELEGRAM = 'TELEGRAM',
  DISCORD = 'DISCORD',
  YOUTUBE = 'YOUTUBE',
  INSTAGRAM = 'INSTAGRAM',
  DE_BANK = 'DE_BANK',
  TON_CHURCH = 'TON_CHURCH',
  HOLD_COIN = 'HOLD_COIN',
  HEXN = 'HEXN',
  BOOM = 'BOOM',
  BABOON = 'BABOON',
  ROCKET = 'ROCKET',
  TOM = 'TOM',
  KOLO = 'KOLO',
  TOMARKET = 'TOMARKET',
  PIGGY = 'PIGGY',
  BFD = 'BFD',
  CORN = 'CORN',
  GOLD_MINER = 'GOLD_MINER',
  BULLISH_FARM = 'BULLISH_FARM',
  ZIGZAG = 'ZIGZAG',
  CRYPTO_RANK = 'CRYPTO_RANK',
  DIA_MORE = 'DIA_MORE',
  EASY_CAKE = 'EASY_CAKE',
  TIME_FARM = 'TIME_FARM',
  MEME_FI = 'MEME_FI',
  ONUS = 'ONUS',
  NOMIS = 'NOMIS',
  SHUTTLE = 'SHUTTLE',
  BITS = 'BITS',
  DUCK_COOP = 'DUCK_COOP',
  ALCHEMY = 'ALCHEMY',
  BLUE_ROCKET = 'BLUE_ROCKET',
  PIXELS = 'PIXELS',
  TELEGRAM_SUBSCRIBE = 'TELEGRAM_SUBSCRIBE',
  TWITTER_SUBSCRIBE = 'TWITTER_SUBSCRIBE',
  TWITTER_RETWEET = 'TWITTER_RETWEET',
}
