import { makeAutoObservable } from 'mobx';

import { type JobSchema, WidgetsJobApiService } from '~shared/api/client';

export class JobStore {
  latestJob: JobSchema | null;

  loaded: {
    latestJob: boolean;
  };

  constructor() {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'jobStore' });

    this.latestJob = null;
    this.loaded = {
      latestJob: false,
    };
  }

  resetJob(): void {
    this.latestJob = null;
  }

  async getLatestJob(projectId: string, userId: string): Promise<JobSchema | undefined> {
    try {
      const jobResponse = await WidgetsJobApiService.getLatestApiV2WidgetUsersUserIdProjectsProjectIdLatestJobGet(
        userId,
        projectId
      );
      this.latestJob = jobResponse;
      this.loaded.latestJob = true;
      return jobResponse;
    } catch (error) {
      console.error(error);
    } finally {
      this.loaded.latestJob = true;
    }
  }

  async startJob(projectId: string, userId: string): Promise<JobSchema | undefined> {
    try {
      const jobResponse = await WidgetsJobApiService.createApiV2WidgetUsersUserIdProjectsProjectIdJobsPost(
        userId,
        projectId
      );
      this.latestJob = jobResponse;
      this.loaded.latestJob = true;
      return jobResponse;
    } catch (error) {
      console.error(error);
    }
  }

  async claimJobReward(projectId: string, userId: string, jobId: string): Promise<JobSchema | undefined> {
    try {
      const jobResponse =
        await WidgetsJobApiService.completeApiV2WidgetUsersUserIdProjectsProjectIdJobsJobIdCompletePost(
          jobId,
          userId,
          projectId
        );
      this.latestJob = jobResponse;
      return jobResponse;
    } catch (error) {
      console.error(error);
    }
  }
}
