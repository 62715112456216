import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useStore } from 'app/store';
import cn from 'classnames';
import * as dateFns from 'date-fns';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { WidgetsWalletsApiService, type UserProjectSchema } from '~shared/api/client';
import { ChevronRightIcon } from '~shared/assets/icons/chevrons';
import { isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';
import { AnimatedCounter, Button, Hedgehog } from '~shared/ui';

interface ProjectLinkProps {
  project: UserProjectSchema;
  onSelect: () => void;
}

export const ProjectLink: React.FC<ProjectLinkProps> = observer(({ project, onSelect }) => {
  const {
    projectStore: { selectProject },
    userStore: { user },
  } = useStore();

  const timerRef = useRef<NodeJS.Timer>();
  const [wallet, setWallet] = useState<string>('');
  const [minutesRemaining, setMinutesRemaining] = useState(0);

  useEffect(() => {
    clearInterval(timerRef.current);
    const handleTimerUpdate = (): void => {
      const secondsRemaning = dateFns.differenceInSeconds(
        isDefined(project.air_drop_time)
          ? new Date(project.air_drop_time * 1000)
          : dateFns.addMinutes(new Date(), new Date().getTimezoneOffset()),
        dateFns.addMinutes(new Date(), new Date().getTimezoneOffset())
      );

      setMinutesRemaining(Math.trunc(secondsRemaning / 60));
    };

    handleTimerUpdate();
    timerRef.current = setInterval(handleTimerUpdate, 5000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [timerRef, project]);

  const getProjectWallet = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(project.id)) return;
    try {
      const walletResponse =
        await WidgetsWalletsApiService.getSelfWalletApiV2WidgetUsersUserIdProjectsProjectIdWalletsSelfGet(
          user.id,
          project.id
        );
      setWallet(walletResponse.address);
    } catch (error) {
      console.error(error);
    }
  }, [project, user]);

  useEffect(() => {
    if (project.is_active) {
      void getProjectWallet();
    }
  }, [getProjectWallet, project]);

  return (
    <div className="flex items-center space-x-4" key={project.id}>
      {project.is_root ? (
        <Hedgehog className="w-24 shrink-0" color="blue" />
      ) : (
        <img className="w-24 shrink-0 overflow-hidden rounded-full" src={project.logo_url ?? ''} alt="" />
      )}

      {(project.is_root || wallet.length > 0) && project.is_active ? (
        <Button
          className={cn(
            'w-full' /* , {
          'opacity-50 pointer-events-none':
            isNil(user) || isNil(user.twitter_profile) || user.twitter_profile.length === 0,
        } */
          )}
          buttonColor="blue"
          onClick={() => {
            selectProject(project);
          }}
          asChild
        >
          <Link to={AppRoutes.Home}>
            <div className="mr-auto flex flex-col items-start">
              <span className="text-md">{project.name}</span>
              <span className="text-sm">
                {project.balance} {project.symbol}
              </span>
              {minutesRemaining > 0 && (
                <div className="flex flex-col text-xs text-white/60">
                  <span>Time until the airdrop:</span>
                  <div className="relative flex h-4 items-center">
                    <AnimatedCounter value={Math.trunc(minutesRemaining / 60 / 24).toString()} />d
                    <AnimatedCounter className="ml-1" value={(Math.trunc(minutesRemaining / 60) % 24).toString()} />h
                    <AnimatedCounter className="ml-1" value={(minutesRemaining % 60).toString()} />m
                  </div>
                </div>
              )}
            </div>

            <ChevronRightIcon className="ml-auto size-8" />
          </Link>
        </Button>
      ) : (
        <Button
          className="min-h-[76px] w-full"
          buttonColor="blue"
          // disabled={isNil(user) || isNil(user.twitter_profile) || user.twitter_profile.length === 0}
          onClick={onSelect}
        >
          <div className="mr-auto flex flex-col items-start">
            <span className="text-md">{project.name}</span>
            {project.is_active && (
              <span className="text-sm">
                {project.balance} {project.symbol}
              </span>
            )}
            {minutesRemaining > 0 && (
              <div className="flex flex-col text-xs text-white/60">
                <span>Time until the airdrop:</span>
                <div className="relative flex h-4 items-center">
                  <AnimatedCounter value={Math.trunc(minutesRemaining / 60 / 24).toString()} />d
                  <AnimatedCounter className="ml-1" value={(Math.trunc(minutesRemaining / 60) % 24).toString()} />h
                  <AnimatedCounter className="ml-1" value={(minutesRemaining % 60).toString()} />m
                </div>
              </div>
            )}
          </div>

          <ChevronRightIcon className="ml-auto size-8" />
        </Button>
      )}
    </div>
  );
});
