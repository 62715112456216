import { makeAutoObservable } from 'mobx';

import { type UserSchema, WebappAuthService } from '~shared/api/client';

export class UserStore {
  user: UserSchema | null;

  loaded: {
    user: boolean;
  };

  constructor() {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'userStore' });

    this.user = null;
    this.loaded = {
      user: false,
    };
  }

  async getUser(): Promise<UserSchema | undefined> {
    try {
      const inviter: string | null = window.Telegram?.WebApp?.initDataUnsafe.start_param ?? null;
      const inviterId: number | null = inviter != null ? Number(inviter) : null;
      const userReponse = await WebappAuthService.loginWebappApiV2AuthWebappLoginGet(inviterId);
      this.user = userReponse;
      this.loaded.user = true;
      return userReponse;
    } catch (error) {
      console.error(error);
    } finally {
      this.loaded.user = true;
    }
  }
}
