import { useCallback, useEffect, useState } from 'react';

import { useStore } from 'app/store';

import { WidgetsStructuresApiService } from '~shared/api/client';
import { isDefined } from '~shared/lib';

export type StructureCountInfo = {
  level1: number;
  level2: number;
  level3: number;
};

export const useStructureCount = (): StructureCountInfo | null => {
  const {
    userStore: { user },
  } = useStore();
  const [structureCount, setStructureCount] = useState<StructureCountInfo | null>(null);

  const handleGetStructureCount = useCallback(async (id: string) => {
    try {
      const [level1Response, level2Response, level3Response] = await Promise.all(
        Array(3)
          .fill(null)
          .map((_, index) =>
            WidgetsStructuresApiService.getStructuresCountApiV2WidgetUsersUserIdStructuresCountGet(id, index + 1)
          )
      );
      setStructureCount({
        level1: level1Response.count,
        level2: level2Response.count,
        level3: level3Response.count,
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id)) {
      void handleGetStructureCount(user.id);
    }
  }, [handleGetStructureCount, user]);

  return structureCount;
};
