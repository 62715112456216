import React, { useEffect, useState } from 'react';

import { SelectValue } from '@radix-ui/react-select';
import { useStore } from 'app/store';
// import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import { ProjectLink } from '~features/project-link';
import { type UserProjectSchema } from '~shared/api/client';
// import { EditIcon } from '~shared/assets/icons';
import { isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';
import { Hedgehog, Page, Select, SelectContent, SelectGroup, SelectTrigger } from '~shared/ui';
import { ProjectDescriptionDrawer } from '~widgets/project-description-drawer';
import { ProjectDrawer } from '~widgets/project-drawer';
import { XAccountDrawer } from '~widgets/x-account-drawer';

export const ProjectsPage: React.FC = observer(() => {
  const {
    jobStore: { resetJob },
    projectStore: { projects, getProjects, resetProject },
    userStore: { user },
  } = useStore();
  const [isXAccountDrawerOpen, setIsXAccountDrawerOpen] = useState(false);
  const [selectedProjectDrawerProject, setSelectedProjectDrawerProject] = useState<UserProjectSchema | null>(null);
  const [selectedProjectDescriptionProject, setSelectedProjectDescriptionProject] = useState<UserProjectSchema | null>(
    null
  );

  useEffect(() => {
    if (isDefined(user)) {
      void getProjects(user.id ?? '');
    }
  }, [user, getProjects]);

  useEffect(() => {
    resetProject();
  }, [resetProject]);

  useEffect(() => {
    resetJob();
  }, [resetJob]);

  return (
    <>
      <Page background="multicolor">
        {/* <div className="mt-4 flex w-full flex-col items-center rounded-[10px] border-2 border-white/50 p-5 text-center">
          {isDefined(user?.twitter_profile) ? (
            <>
              <h2 className="text-xl">Your X (Twitter) Account</h2>
              <p className="mt-2 text-base text-white/50">
                To participate in the airdrop, enter your X (Twitter) username
              </p>
              <div className="mt-4 flex items-center space-x-4">
                <span className="text-md">{user?.twitter_profile}</span>
                <Button
                  variant="wrapper"
                  size="wrapper"
                  onClick={() => {
                    setIsXAccountDrawerOpen(true);
                  }}
                >
                  <EditIcon className="size-6" />
                </Button>
              </div>
            </>
          ) : (
            <>
              <h2 className="text-xl">Connect X (Twitter) Account</h2>
              <p className="mt-2 text-base text-white/50">
                To participate in the airdrop, enter your X (Twitter) username
              </p>
              <Button
                className="mt-4 px-8 py-2 text-md font-bold"
                buttonColor="green"
                onClick={() => {
                  setIsXAccountDrawerOpen(true);
                }}
              >
                Connect
              </Button>
            </>
          )}
        </div> */}

        <div className="m-auto w-full flex-col space-y-4">
          {projects?.results.map((project) => (
            <ProjectLink
              key={project.id}
              project={project}
              onSelect={() => {
                if (project.is_active) {
                  setSelectedProjectDrawerProject(project);
                } else {
                  setSelectedProjectDescriptionProject(project);
                }
              }}
            />
          ))}

          <Select>
            <SelectTrigger className="hidden">
              <SelectValue placeholder="Try out other projects" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {projects?.results.map((project) => (
                  <Link
                    className="relative flex w-full select-none items-center rounded-xl py-1.5 pl-2 pr-8 text-sm outline-none transition-all hover:bg-blue hover:text-accent-foreground"
                    onClick={() => {
                      setSelectedProjectDrawerProject(project);
                    }}
                    key={project.id}
                    to={AppRoutes.Home}
                  >
                    <div className="flex items-center space-x-2">
                      {isDefined(project.logo_url) && !project.is_root && (
                        <img className="size-8 overflow-hidden rounded-full" src={project.logo_url} alt="" />
                      )}
                      {project.is_root && <Hedgehog className="size-8" color="blue" showGlowing={false} />}
                      <div className="flex flex-col">
                        <span className="text-base">{project.name}</span>
                        <span className="text-xs">
                          {project.balance} {project.symbol}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
      </Page>

      <ProjectDrawer
        project={selectedProjectDrawerProject}
        onClose={() => {
          setSelectedProjectDrawerProject(null);
        }}
      />

      <ProjectDescriptionDrawer
        project={selectedProjectDescriptionProject}
        onClose={() => {
          setSelectedProjectDescriptionProject(null);
        }}
      />

      <XAccountDrawer
        isOpen={isXAccountDrawerOpen}
        onClose={(newOpen) => {
          setIsXAccountDrawerOpen(newOpen);
        }}
      />
    </>
  );
});
