import type React from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import coinImage from '~shared/assets/images/_coin.png';
import { isDefined } from '~shared/lib';

export interface AmountProps {
  className?: string;
  value?: string | null;
  logo?: string | null;
}

export const Amount: React.FC<AmountProps> = ({ value, className, logo }) => {
  return (
    <div className={twMerge('flex items-center justify-center', className)}>
      <img
        className={cn('mr-2 size-8', { 'overflow-hidden rounded-full': isDefined(logo) })}
        src={logo ?? coinImage}
        alt=""
      />
      <span className="text-4xl font-bold">{value}</span>
    </div>
  );
};
