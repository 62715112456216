import { useCallback, useEffect, useRef, useState } from 'react';

import { useStore } from 'app/store';
import * as dateFns from 'date-fns';
import { isNil } from 'lodash';

import { WidgetsStructureBonusesApiService, type GetStructureBonusResponse } from '~shared/api/client';
import { isDefined } from '~shared/lib';

export type BonusesInfo = {
  bonuses: GetStructureBonusResponse | null;
  loaded: boolean;
  minutesRemaining: number;
  handleClaimBonuses: () => Promise<void>;
  isClaimLoading: boolean;
};

export const useBonuses = (): BonusesInfo => {
  const {
    userStore: { user, getUser },
    projectStore: { project, getProject },
  } = useStore();
  const [bonuses, setBonuses] = useState<GetStructureBonusResponse | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);

  const bonusesPollingInterval = useRef<NodeJS.Timer>();

  const minutesRemaining =
    isDefined(bonuses) && isDefined(bonuses.next_claimed_at)
      ? dateFns.differenceInMinutes(
          new Date(bonuses.next_claimed_at),
          dateFns.addMinutes(new Date(), new Date().getTimezoneOffset())
        )
      : 0;

  const handleGetBonuses = useCallback(async (projectId: string, userId: string) => {
    try {
      const bonusesResponse =
        await WidgetsStructureBonusesApiService.getUserBonusesApiV2WidgetUsersUserIdProjectsProjectIdBonusesGet(
          userId,
          projectId
        );
      setBonuses(bonusesResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setLoaded(true);
    }
  }, []);

  const handleClaimBonuses = useCallback(async () => {
    if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id)) return;

    try {
      setIsClaimLoading(true);
      Telegram.WebApp.HapticFeedback.notificationOccurred('success');
      const bonusesResponse =
        await WidgetsStructureBonusesApiService.claimBonusesApiV2WidgetUsersUserIdProjectsProjectIdBonusesClimePost(
          user.id,
          project.id
        );
      await getUser();
      await getProject(project.id, user.id);
      setBonuses(bonusesResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setIsClaimLoading(false);
      setLoaded(true);
    }
  }, [user, project, getUser, getProject]);

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id) && isDefined(project) && isDefined(project.id)) {
      void handleGetBonuses(project.id, user.id);
      bonusesPollingInterval.current = setInterval(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        void handleGetBonuses(project.id!, user.id!);
      }, 60 * 1000);
    }

    return () => {
      clearInterval(bonusesPollingInterval.current);
    };
  }, [handleGetBonuses, user, project]);

  return { bonuses, loaded, minutesRemaining, handleClaimBonuses, isClaimLoading };
};
