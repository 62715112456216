import React from 'react';

import cn from 'classnames';

import { useScreenDimensions } from '~shared/lib';

interface StarsBgProps {
  className?: string;
}

const StarsBg: React.FC<StarsBgProps> = React.memo(({ className }) => {
  const { width, height } = useScreenDimensions();

  return (
    <div className={className}>
      {Array(100)
        .fill(null)
        .map((_, index) => (
          <div
            key={index}
            className={cn(
              'absolute animate-glow rounded-full bg-[rgb(237_235_163_/_80%)] shadow-[0_0_40px_0_rgb(237_235_163_/_80%),_0_0_20px_0_#fff]',
              index % 20 === 0 ? 'size-2' : index % 9 === 0 ? 'size-1' : 'size-[2px]'
            )}
            style={{
              top: `${Math.round(Math.random() * height)}px`,
              left: `${Math.round(Math.random() * width)}px`,
              animationDuration: `${Math.round(Math.random() * 3000) + 3000}ms`,
              animationDelay: `${Math.round(Math.random() * 3000)}ms`,
            }}
          />
        ))}
    </div>
  );
});
StarsBg.displayName = 'StarsBg';

export { StarsBg };
