/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { GetCountResponse } from '../models/GetCountResponse';
import type { GetStructuresListResponse } from '../models/GetStructuresListResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsStructuresApiService {
  /**
   * Get Structures
   * Get the structure by depth
   * @param userId
   * @param depth
   * @param projectId
   * @param limit
   * @param offset
   * @returns GetStructuresListResponse Successful Response
   * @throws ApiError
   */
  public static getStructuresApiV2WidgetUsersUserIdStructuresGet(
    userId: string,
    depth: number,
    projectId?: string,
    limit: number = 100,
    offset?: number
  ): CancelablePromise<GetStructuresListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/structures',
      path: {
        user_id: userId,
      },
      query: {
        project_id: projectId,
        depth: depth,
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Structures Count
   * Get the number of child in the structure. From 1 to `depth`
   * @param userId
   * @param depth
   * @returns GetCountResponse Successful Response
   * @throws ApiError
   */
  public static getStructuresCountApiV2WidgetUsersUserIdStructuresCountGet(
    userId: string,
    depth: number = 3
  ): CancelablePromise<GetCountResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/structures_count',
      path: {
        user_id: userId,
      },
      query: {
        depth: depth,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
