import React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { Spinner } from '../spinner';

const MotionSpinner = motion(Spinner);

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring active:opacity-70 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'rounded-[10px]',
        link: 'text-primary underline-offset-4 hover:underline',
        wrapper: '',
      },
      buttonColor: {
        green: '',
        blue: '',
        red: '',
        primary: '',
      },
      size: {
        default: 'p-4 text-lg font-normal',
        icon: 'h-9 w-9',
        wrapper: 'p-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
      buttonColor: 'primary',
    },
    compoundVariants: [
      {
        buttonColor: 'green',
        variant: 'default',
        className: 'bg-green text-white',
      },
      {
        buttonColor: 'red',
        variant: 'default',
        className: 'bg-red text-white',
      },
      {
        buttonColor: 'blue',
        variant: 'default',
        className: 'bg-blue text-white',
      },
      {
        buttonColor: 'primary',
        variant: 'default',
        className: 'bg-white text-[#191C26]',
      },
    ],
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  spinnerClassName?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      variant,
      size,
      spinnerClassName,
      disabled,
      isLoading = false,
      asChild = false,
      buttonColor,
      ...props
    },
    ref
  ) => {
    return asChild ? (
      <Slot className={twMerge(buttonVariants({ variant, size, buttonColor }), className)} ref={ref} {...props}>
        {children}
      </Slot>
    ) : (
      <button
        className={twMerge(buttonVariants({ variant, size, buttonColor }), className)}
        ref={ref}
        {...props}
        disabled={disabled ?? isLoading}
      >
        <>
          <AnimatePresence>
            {isLoading && (
              <MotionSpinner
                className={twMerge(spinnerClassName)}
                variants={{
                  show: { opacity: 1, marginRight: '8px', width: 'auto' },
                  hide: { opacity: 0, marginRight: '0px', width: '0px' },
                }}
                initial="hide"
                animate="show"
                exit="hide"
              />
            )}
          </AnimatePresence>
          {children}
        </>
      </button>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
