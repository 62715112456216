/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { UpdateWalletSchema } from '../models/UpdateWalletSchema';
import type { WalletSchema } from '../models/WalletSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsWalletsApiService {
  /**
   * Get Self Wallet
   * Get self wallet
   * @param userId
   * @param projectId
   * @returns WalletSchema Successful Response
   * @throws ApiError
   */
  public static getSelfWalletApiV2WidgetUsersUserIdProjectsProjectIdWalletsSelfGet(
    userId: string,
    projectId: string
  ): CancelablePromise<WalletSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/wallets/self',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Update Self Wallet
   * @param userId
   * @param projectId
   * @param requestBody
   * @returns WalletSchema Successful Response
   * @throws ApiError
   */
  public static updateSelfWalletApiV2WidgetUsersUserIdProjectsProjectIdWalletsSelfPut(
    userId: string,
    projectId: string,
    requestBody: UpdateWalletSchema
  ): CancelablePromise<WalletSchema> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/wallets/self',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Delete Self Wallet
   * @param userId
   * @param projectId
   * @returns void
   * @throws ApiError
   */
  public static deleteSelfWalletApiV2WidgetUsersUserIdProjectsProjectIdWalletsSelfDelete(
    userId: string,
    projectId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/wallets/self',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
