import React from 'react';

import { type VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

export interface PageProps extends React.PropsWithChildren, VariantProps<typeof pageVariants> {
  className?: string;
}

const pageVariants = cva('flex h-full flex-col overflow-auto bg-cover bg-center bg-top px-6 pb-[112px] pt-10', {
  variants: {
    background: {
      blue: 'bg-blue-page',
      red: 'bg-red-page',
      green: 'bg-green-page',
      multicolor: 'bg-multicolor-page',
    },
  },
  defaultVariants: {
    background: 'multicolor',
  },
});

export const Page: React.FC<PageProps> = ({ children, background, className }) => {
  return <div className={twMerge(pageVariants({ background }), className)}>{children}</div>;
};
