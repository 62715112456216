import { useEffect, useState } from 'react';

import { debounce } from 'lodash';

export type ScreenDimensions = {
  width: number;
  height: number;
};

export const useScreenDimensions = (): ScreenDimensions => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 100) as () => void;
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...windowSize };
};
