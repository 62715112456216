import { useCallback, useEffect, useState } from 'react';

import { useStore } from 'app/store';

import { WidgetsTasksApiService, type GetUserTasksListResponse } from '~shared/api/client';
import { isDefined } from '~shared/lib';

export type TasksInfo = {
  staticTasks: GetUserTasksListResponse | null;
  activeTasks: GetUserTasksListResponse | null;
};

export const useTasks = (): TasksInfo => {
  const {
    userStore: { user },
    projectStore: { project },
  } = useStore();
  const [staticTasks, setStaticTasks] = useState<GetUserTasksListResponse | null>(null);
  const [activeTasks, setActiveTasks] = useState<GetUserTasksListResponse | null>(null);

  const handleGetStaticTasks = useCallback(async (projectId: string, userId: string) => {
    try {
      const structureResponse =
        await WidgetsTasksApiService.getStaticApiV2WidgetUsersUserIdProjectsProjectIdStaticTasksGet(userId, projectId);
      setStaticTasks(structureResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleGetActiveTasks = useCallback(async (projectId: string, userId: string) => {
    try {
      const structureCountResponse =
        await WidgetsTasksApiService.getActiveApiV2WidgetUsersUserIdProjectsProjectIdActiveTasksGet(userId, projectId);
      setActiveTasks(structureCountResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id) && isDefined(project) && isDefined(project.id)) {
      void handleGetStaticTasks(project.id, user.id);
      void handleGetActiveTasks(project.id, user.id);
    }
  }, [handleGetStaticTasks, handleGetActiveTasks, user, project]);

  return { staticTasks, activeTasks };
};
