/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { JobSchema } from '../models/JobSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsJobApiService {
  /**
   * Get Latest
   * @param userId
   * @param projectId
   * @returns JobSchema Successful Response
   * @throws ApiError
   */
  public static getLatestApiV2WidgetUsersUserIdProjectsProjectIdLatestJobGet(
    userId: string,
    projectId: string
  ): CancelablePromise<JobSchema> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/latest_job',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Create
   * @param userId
   * @param projectId
   * @returns JobSchema Successful Response
   * @throws ApiError
   */
  public static createApiV2WidgetUsersUserIdProjectsProjectIdJobsPost(
    userId: string,
    projectId: string
  ): CancelablePromise<JobSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/jobs',
      path: {
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Complete
   * @param jobId
   * @param userId
   * @param projectId
   * @returns JobSchema Successful Response
   * @throws ApiError
   */
  public static completeApiV2WidgetUsersUserIdProjectsProjectIdJobsJobIdCompletePost(
    jobId: string,
    userId: string,
    projectId: string
  ): CancelablePromise<JobSchema> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/users/{user_id}/projects/{project_id}/jobs/{job_id}/complete',
      path: {
        job_id: jobId,
        user_id: userId,
        project_id: projectId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
