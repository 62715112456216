import React, { useCallback, useState } from 'react';

import { useStore } from 'app/store';
import cn from 'classnames';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { type UserTaskSchema } from '~shared/api/client';
import { CheckIcon } from '~shared/assets/icons';
import { ChevronRightIcon } from '~shared/assets/icons/chevrons';
import { TASK_TYPE_MAP } from '~shared/assets/icons/tasks';
import { formatAmount, isDefined } from '~shared/lib';
import { Amount, Button, Hedgehog, Page, Skeleton } from '~shared/ui';
import { TaskDrawer } from '~widgets/task-drawer';

import { useTasks } from '../lib';

export const TasksPage: React.FC = observer(() => {
  const {
    userStore: { user, getUser },
    projectStore: { project, getProject },
  } = useStore();
  const { activeTasks, staticTasks } = useTasks();
  const [selectedTask, setSelectedTask] = useState<UserTaskSchema | null>(null);

  const handleCompleteTask = useCallback(async () => {
    try {
      if (isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id)) return;
      await getUser();
      await getProject(project.id, user.id);
      setSelectedTask(null);
    } catch (error) {}
  }, [getUser, getProject, project, user]);

  return (
    <>
      <Page className="items-center">
        <Hedgehog className="w-[136px]" color="green" />
        <h1 className="text-center text-lg font-bold uppercase">Tasks</h1>
        <p className="mt-1 text-center text-white/50">Complete tasks and get more coins</p>

        <div className="mt-6 flex w-full flex-col space-y-5">
          <h2 className="text-left text-md font-bold">Daily task</h2>

          <div className="flex w-full flex-col space-y-2">
            {isNil(activeTasks) && <Skeleton className="h-[72px] w-full" />}
            {isDefined(activeTasks) && activeTasks.count === 0 && <p>No daily tasks for now</p>}
            {isDefined(activeTasks) &&
              activeTasks.results.map((task) => {
                const TaskIcon = TASK_TYPE_MAP[task.type];
                return (
                  <Button
                    key={task.id}
                    className={cn(
                      'flex w-full items-center rounded-[10px] p-3 disabled:opacity-100',
                      task.is_completed ? 'bg-white/5' : 'bg-white/10'
                    )}
                    asChild
                    variant="wrapper"
                    size="wrapper"
                    disabled={task.is_completed}
                    onClick={() => {
                      Telegram.WebApp.HapticFeedback.notificationOccurred('success');
                      setSelectedTask(task);
                    }}
                  >
                    <a href={task.link} target="_blank" rel="noreferrer">
                      {typeof TaskIcon === 'string' ? (
                        <img
                          className="mr-3 size-[46px] overflow-hidden rounded-full object-cover object-center"
                          src={TaskIcon}
                          alt=""
                        />
                      ) : (
                        <TaskIcon className="mr-3 size-[46px]" />
                      )}
                      <div className="mr-3 flex flex-col">
                        <p className="text-base font-normal">{task.name}</p>
                        <Amount
                          className="justify-start [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
                          value={`+${formatAmount(parseFloat(task.amount))}`}
                          logo={project?.logo_url}
                        />
                      </div>

                      {task.is_completed ? (
                        <CheckIcon className="ml-auto size-6" />
                      ) : (
                        <ChevronRightIcon className="ml-auto size-6" />
                      )}
                    </a>
                  </Button>
                );
              })}
          </div>
        </div>

        <div className="mt-6 flex w-full flex-col space-y-5">
          <h2 className="text-left text-md font-bold">Task list</h2>

          <div className="flex w-full flex-col space-y-2">
            {isNil(staticTasks) && <Skeleton className="h-[72px] w-full" />}
            {isDefined(staticTasks) && staticTasks.count === 0 && <p>No tasks for now</p>}
            {isDefined(staticTasks) &&
              staticTasks.results.map((task) => {
                const TaskIcon = TASK_TYPE_MAP[task.type];
                return (
                  <Button
                    key={task.id}
                    className={cn(
                      'flex w-full items-center rounded-[10px] p-3 disabled:opacity-100',
                      task.is_completed ? 'bg-white/5' : 'bg-white/10'
                    )}
                    asChild
                    variant="wrapper"
                    size="wrapper"
                    disabled={task.is_completed}
                    onClick={() => {
                      Telegram.WebApp.HapticFeedback.notificationOccurred('success');
                      setSelectedTask(task);
                    }}
                  >
                    <a href={task.link} target="_blank" rel="noreferrer">
                      {typeof TaskIcon === 'string' ? (
                        <img
                          className="mr-3 size-[46px] overflow-hidden rounded-full object-cover object-center"
                          src={TaskIcon}
                          alt=""
                        />
                      ) : (
                        <TaskIcon className="mr-3 size-[46px]" />
                      )}
                      <div className="mr-3 flex flex-col">
                        <p className="text-base font-normal">{task.name}</p>
                        <Amount
                          className="justify-start [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
                          value={`+${formatAmount(parseFloat(task.amount))}`}
                          logo={project?.logo_url}
                        />
                      </div>

                      {task.is_completed ? (
                        <CheckIcon className="ml-auto size-6" />
                      ) : (
                        <ChevronRightIcon className="ml-auto size-6" />
                      )}
                    </a>
                  </Button>
                );
              })}
          </div>
        </div>
      </Page>

      <TaskDrawer
        task={selectedTask}
        onClose={(newOpen) => {
          if (!newOpen) {
            setSelectedTask(null);
          }
        }}
        onComplete={handleCompleteTask}
      />
    </>
  );
});
